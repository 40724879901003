
import React from 'react'
import PropTypes from 'prop-types'
import Section from "./section";
import twitter from '../images/twitter.svg';


const Footer = (props) => {
    return (
        <Section containerClass="pt-4">
            <div className="pt-8 pb-8" >
                <div className="flex justify-between">
                    <div><a href="mailto:info@davcon.se" className="text-md font-bold text-blue no-underline hover:underline">CONTACT US</a></div>
                    <div><a href="https://www.twitter.com/davconsulting"><img src={twitter} width="26px" height="26px" alt="" /></a></div>
                </div>
                <div className="flex justify-between">
                    <div className="text-xs">Copyright © 2018-2019 DAVCON AB</div>
                    <div><a href="mailto:info@davcon.se" className="text-blue no-underline hover:underline">info@davcon.se</a></div>
                </div>
            </div>
        </Section>
    )
}


Footer.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Footer


