import tw from "tailwind.macro"
import styled, { css } from "styled-components"

export const dynamicStyle = props =>
  css`
    color: ${props.color};
    margin-left: ${props.marginLeft};
  `

export const LinkButtonHeader = styled.a`
  ${tw`inline-block no-underline text-sm lg:mr-2 lg:ml-4 px-4 py-2 leading-none border rounded text-blue border-blue hover:border-teal hover:text-teal hover:bg-white mt-4 lg:mt-0`};
`

export const H2 = styled.div`
  ${dynamicStyle};
  ${tw`text-xl md:text-3xl w-full`};
`

export const LinkButton = styled.a`
 ${dynamicStyle};
  transition: linear 0.5s;
  ${tw`inline-block no-underline text-sm text-center pl-4 pr-2 py-3 leading-none border rounded bg-teal text-white border-teal hover:border-teal-light hover:text-white hover:bg-teal-light hover:mb-2`};
`

export const LinkButtonInverted = styled.a`
  ${tw`inline-block no-underline text-sm px-4 py-2 leading-none border rounded text-blue border-blue hover:border-teal hover:text-teal hover:bg-white`};
`

export const PageLink = styled.a`
  ${dynamicStyle};
  ${tw`inline-block no-underline text-blue cursor-pointer hover:text-blue-dark`};
`

export const SubHeader = styled.div`
  ${dynamicStyle};
  ${tw`bg-very-lightest-grey border-solid border border-very-grey-light w-full h-22 px-4 md:px-32  py-12 md:py-20`};
`

export const Content = styled.div`
  ${tw`flex px-32 py-5`};
`


export const ImageBox = styled.div`
  ${dynamicStyle};
  ${tw`flex-1 bg-grey-light px-0 py-0`};
`