import React from 'react'
import styled from "styled-components"
import tw from "tailwind.macro"
import { LinkButtonHeader } from '../css/stylecomp'
// import Img from 'gatsby-image'
import logo from '../images/dav_consulting.svg';
// import arrow from '../images/ios-arrow-down-svg2.svg';
import { Link } from "gatsby"


// justify-between
// const Nav = styled.div`
//   ${tw`text-grey font-small leading-loose text-center px-10`};
// `
// const NavLink = styled.a`
//   ${tw`text-grey`};
//`

const ImageBox = styled.div`
  ${tw`bg-white w-64 h-8 px-0 pt-2`};
`
// const Button = styled.button`
//   ${tw`font-normal py-2 px-4 rounded`};
//   ${tw`bg-blue hover:bg-blue-dark text-white`};
// `


// const NonStretchedImage = props => {
//   let normalizedProps = props
//   if (props.fluid && props.fluid.presentationWidth) {
//     normalizedProps = {
//       ...props,
//       style: {
//         ...(props.style || {}),
//         maxWidth: props.fluid.presentationWidth,
//         margin: "0 auto", // Used to center the image
//       },
//     }
//   }
//   // console.log('normalizedProps', normalizedProps)
//   return <Img {...normalizedProps} />
// }

// className="open ? 'block': 'hidden'" 
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showServicesMenu: false };
    this.openServicesMenu = this.openServicesMenu.bind(this)
    this.closeServicesMenu = this.closeServicesMenu.bind(this);
  }

  openServicesMenu() {
    console.log('toggleMenu', this.state.showServicesMenu)
    this.setState((prevState) => ({ showServicesMenu: !prevState.showServicesMenu }), () => {
      document.addEventListener('click', this.closeServicesMenu);
    })
  }

  closeServicesMenu() {
    this.setState({ showServicesMenu: false }, () => {
      document.removeEventListener('click', this.closeServicesMenu);
    });
  }

  render() {
    const { toggleFunction, toggleState } = this.props;
    const menuState = toggleState ? 'block' : 'hidden'
    // const menuItemAlign = toggleState ? 'text-right px-4' : 'text-left'
    // const Nimg = NonStretchedImage({ fluid: img })

    // const { showServicesMenu } = this.state;
    // const services = showServicesMenu ? 'block' : 'hidden';
    return (
      <nav className="flex items-center justify-between flex-wrap bg-white xl:w-4/5 lg:w-wlg md:w-wmd px-4 ml-auto mr-auto py-5" >
        <div className="flex items-center flex-no-shrink text-white">
          <ImageBox>
            <a href="/"><img src={logo} className="bg-white" alt="logo" /></a>
          </ImageBox>
        </div>
        <div className="block lg:hidden">
          <button onClick={() => toggleFunction()} className="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-teal hover:border-teal">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>
        <div className={`w-full flex-grow lg:flex lg:items-center lg:w-auto ${menuState}`}>
          <div className={`text-sm lg:flex-grow lg:text-right `}>
            <Link to="/#about" className="block mt-4 lg:inline-block lg:mt-0 no-underline text-xs text-grey-dark hover:text-grey-darker mr-4">ABOUT</Link>
            <Link to="/#example_projects" className="block mt-4 lg:inline-block lg:mt-0 no-underline text-xs text-grey-dark hover:text-grey-darker mr-4">PROJECTS</Link>
            <Link to="/#media" className="block mt-4 lg:inline-block lg:mt-0 no-underline text-xs text-grey-dark hover:text-grey-darker mr-4">MEDIA</Link>
            <Link to="/#story" className="block mt-4 lg:inline-block lg:mt-0 no-underline text-xs text-grey-dark hover:text-grey-darker">STORY</Link>
          </div>
          <div>
            <LinkButtonHeader href="/contact" >Contact</LinkButtonHeader>
          </div>
        </div>
      </nav>
    )
  }
}
// ${menuItemAlign}
export default Header
