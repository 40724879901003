import React from 'react'
import PropTypes from 'prop-types'


// xl:w-wxl lg:w-wlg md:w-wmd 

const Section = (props) => {
  return (
    <section className={`text-center  ${props.className}`}>
      <div className={`xl:w-4/5 lg:w-5/6 px-4 ml-auto mr-auto ${props.containerClass}`}>
        {props.children}
      </div>
    </section>
  )
}


Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
