import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from "styled-components"
import tw from "tailwind.macro"

import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
// import './layout.css'

const Content = styled.div`
  ${tw`bg-white px-0 py-0`};
`
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

// height: 50
class Layout extends React.Component {
  // ... constructor and toggleMenu from above
  constructor(props) {
    super(props);
    this.state = { showMenu: false };

  }

  toggleMenu() {
    console.log('toggleMenu', this.state.showMenu)
    this.setState((prevState) => ({ showMenu: !prevState.showMenu }))
    // 
  }

  render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        logo: file(relativePath: { eq: "images/dav_consulting.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_noBase64
              presentationWidth
            }
          }
        }
      }
    `}
        render={data => {
          console.log('data', data)
          return (
            <>
              <Helmet
                title={data.site.siteMetadata.title}
                meta={[
                  { name: 'description', content: 'We are a Data Science company focusing on the cultivation and quantification of unstructured data for generating deeper evidence based insights.' },
                  { name: 'keywords', content: 'data, science, consulting' },
                  { name: 'google-site-verification', content: '4xln0dOdz2G4JWbyO7CzWJZwbPs0BrfMEfKhTMLLoVA' },
                ]}
              >
                <html lang="en" />
              </Helmet>

              <Header siteTitle={data.site.siteMetadata.title} img={data.logo.childImageSharp.fluid} toggleState={this.state.showMenu} toggleFunction={this.toggleMenu.bind(this)} />
              <Content> {children} </Content>

            </>
          )
        }
        }
      />
    )
  }
}


// 
// 
// <Front siteTitle={data.site.siteMetadata.title} img={data.logo.childImageSharp.fluid} />
// <Content> {children} </Content>
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
